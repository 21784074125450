import { render, staticRenderFns } from "./RecentCampsites.vue?vue&type=template&id=13ee55c0&scoped=true"
import script from "./RecentCampsites.vue?vue&type=script&lang=ts"
export * from "./RecentCampsites.vue?vue&type=script&lang=ts"
import style0 from "./RecentCampsites.vue?vue&type=style&index=0&id=13ee55c0&prod&scoped=true&lang=css"
import style1 from "./RecentCampsites.vue?vue&type=style&index=1&id=13ee55c0&prod&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "13ee55c0",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,PuRatingBubble: require('/app/components/campsite/campsite-card/components/PuRatingBubble.vue').default,TimeAgo: require('/app/components/bookings/TimeAgo.vue').default,PuCampsiteLink: require('/app/apps/pu-links/link-campsite/PuCampsiteLink.vue').default})
