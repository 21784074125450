import { render, staticRenderFns } from "./AppMenuCategories.vue?vue&type=template&id=91ce89b4&scoped=true"
import script from "./AppMenuCategories.vue?vue&type=script&lang=ts"
export * from "./AppMenuCategories.vue?vue&type=script&lang=ts"
import style0 from "./AppMenuCategories.vue?vue&type=style&index=0&id=91ce89b4&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "91ce89b4",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {PuCategoryIcons: require('/app/components/campsite/campsite-card/components/PuCategoryIcons.vue').default,PuSearchLink: require('/app/apps/pu-links/link-search/PuSearchLink.vue').default,Translate: require('/app/components/Translate.vue').default})
