import { render, staticRenderFns } from "./CurrencySwitchMenu.vue?vue&type=template&id=ebfa6ba6&scoped=true"
import script from "./CurrencySwitchMenu.vue?vue&type=script&setup=true&lang=ts"
export * from "./CurrencySwitchMenu.vue?vue&type=script&setup=true&lang=ts"
import style0 from "./CurrencySwitchMenu.vue?vue&type=style&index=0&id=ebfa6ba6&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "ebfa6ba6",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {Translate: require('/app/components/Translate.vue').default,CurrencySwitch: require('/app/apps/currencies/components/CurrencySwitch.vue').default,UiCollapse: require('/app/components/ui/UiCollapse.vue').default})
