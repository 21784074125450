
import { Component, Vue } from '~/utility/pu-class-decorator'

@Component
export default class FeefoBadge extends Vue {
  get badgeLink() {
    return this.$i18n.localeInfo.feefoBadge
  }

  get altTagText() {
    return this.$t('Feefo platinum service award {currentYear}', {
      currentYear: new Date().getFullYear(),
    })
  }
}
