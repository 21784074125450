
import { Component, Vue } from '~/utility/pu-class-decorator'

@Component
export default class Copyright extends Vue {
  get currentYear() {
    const d = new Date()
    return d.getFullYear()
  }
}
