
import LazyHydrate from 'vue-lazy-hydration'
import { AppMenuMixin } from '~/apps/app-menu/mixin'
import AuthMixin from '~/mixins/AuthMixin'
import { Component, Mixins, Prop, Watch } from '~/utility/pu-class-decorator'

@Component<AppMenu>({
  components: {
    LazyHydrate,
  },
  head() {
    return {
      bodyAttrs: {
        'data-menu-open': this.appMenuIsVisible ? 'true' : 'false',
      },
    }
  },
})
export default class AppMenu extends Mixins(AuthMixin, AppMenuMixin) {
  locationLoading = false
  locationsOpen = false

  @Prop({ default: false })
    appMenuIsVisible: boolean

  @Prop()
    totalCounts: number

  @Watch('appMenuVisible')
  onAppMenuVisibleChanged(value: boolean) {
    if (!value) {
      this.locationsOpen = false
    }
  }

  onLocationsOpen() {
    this.locationsOpen = true
    this.scrollToHierarchy()
  }

  private scrollToHierarchy() {
    const activeNode = document.querySelector('.active.node') as HTMLElement
    if (activeNode) {
      this.$nextTick(() => {
        activeNode.scrollIntoView({ block: 'center' })
      })
    }
  }

  locationSelected() {
    this.locationsOpen = false
    this.hideMenu()
  }

  canScrollTo() {
    this.$nextTick(() => {
      if (this.locationsOpen) {
        this.scrollToHierarchy()
      }
    })
  }

  get shouldShowArticles() {
    return this.$i18n.locale.startsWith('en-')
  }
}
