
import { usePinia } from '#imports'
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { RecentCampsiteDetails } from '../../../apps/recent-campsites/types'
import { useRecentCampsitesStore } from '../../../stores/useRecentCampsitesStore'

@Component({
  setup() {
    const recentCampsitesStore = useRecentCampsitesStore(usePinia())
    return {
      recentCampsitesStore,
    }
  },
})
export default class AppMenuRecentCampsites extends Vue {
  recentCampsitesStore: ReturnType<typeof useRecentCampsitesStore>

  @Prop({ default: 6 })
    limit: number

  get recentCampsites() {
    return this.recentCampsitesStore.recentCampsites
  }

  async mounted() {
    await this.recentCampsitesStore.refreshCampsites(
      this.$route.params.lang || 'en-gb',
      this.limit,
    )
  }

  getRecentImageUrl(campsite: RecentCampsiteDetails): string {
    if (!campsite.primaryPhoto) {
      return ''
    }
    if (this.$isDesktop) {
      return `/_cfi/cdn-cgi/image/format=auto,fit=cover,quality=80,w=264,h=198${campsite.primaryPhoto.masterImage}`
    }
    return campsite.primaryPhoto.url
  }
}
