import { Interpreter, assign, createMachine } from 'xstate'
import {
  FullHierarchy,
  Hierarchy,
} from '../../../../../apps/hierarchies/domain/types'
import { LocationNode } from './types'

export interface AppMenuLocationsMachineContext {
  locations: LocationNode[]
  currentCountryPath?: string
  currentFullCountry?: FullHierarchy
  currentHierarchyPath: string
  langCode: string
}

interface Event {
  type: 'CHANGE_HIERARCHY'
  path: string
  data: []
}

export function getContextInitialAppMenuLocationsMachine() {
  return {
    locations: [],
    currentHierarchyPath: '',
    langCode: 'en-gb',
  }
}

export function returnAppMenuLocationsMachine() {
  return createMachine<AppMenuLocationsMachineContext, Event>(
    {
      id: 'locations',
      context: getContextInitialAppMenuLocationsMachine(),
      initial: 'loading',
      states: {
        loading: {
          initial: 'bookableCountries',
          states: {
            bookableCountries: {
              invoke: {
                src: 'fetchBookableCountries',
                onDone: 'settingCountries',
                onError: {
                  target: '#locations.error',
                  actions: 'logError',
                },
              },
            },
            settingCountries: {
              entry: assign({
                locations: (_, { data }) => mapCountriesToNodes(data),
              }),
              always: [
                {
                  target: 'hierarchy',
                  cond: ({ currentHierarchyPath }) =>
                    currentHierarchyPath !== '' && currentHierarchyPath !== '/',
                },
                { target: '#locations.showing' },
              ],
            },
            hierarchy: {
              entry: assign({
                currentCountryPath: ({ currentHierarchyPath }) =>
                  getCountryPath(currentHierarchyPath),
              }),
              invoke: {
                src: 'fetchHierarchy',
                onDone: {
                  target: '#locations.showing',
                  actions: [
                    assign({
                      currentFullCountry: (_, { data }) => data,
                    }),
                    'setCountryChildrenNodes',
                  ],
                },
                onError: {
                  target: '#locations.error',
                  actions: 'logError',
                },
              },
            },
          },
        },
        showing: {
          on: {
            CHANGE_HIERARCHY: [
              {
                actions: assign({
                  currentHierarchyPath: (_, { path }) => path,
                  locations: ({ locations }) => {
                    for (const country of locations) {
                      if (country.children.length) {
                        country.children = []
                      }
                    }
                    return locations
                  },
                }),
                cond: (_, { path }) => path === '/',
              },
              {
                actions: assign({
                  currentHierarchyPath: (_, { path }) => path,
                }),
                target: '#locations.loading.hierarchy',
                cond: ({ currentCountryPath }, { path }) =>
                  getCountryPath(path) !== currentCountryPath,
              },
              {
                actions: [
                  assign({
                    currentHierarchyPath: (_, { path }) => path,
                  }),
                  'setCountryChildrenNodes',
                ],
              },
            ],
          },
        },
        error: {},
      },
    },
    {
      actions: {
        setCountryChildrenNodes: assign<AppMenuLocationsMachineContext, Event>({
          locations: (ctx) => {
            const currentLocations = generateNodeHierarchy({
              node: ctx.currentFullCountry!,
              path: ctx.currentHierarchyPath,
            })
            for (const country of ctx.locations) {
              if (country.path === ctx.currentCountryPath) {
                country.children = currentLocations
              } else {
                country.children = []
              }
            }
            return ctx.locations
          },
        }),
        logError: () => {
          throw new Error('fetchBookableCountries logError not implemented')
        },
      },
      services: {
        fetchBookableCountries: () => {
          throw new Error('fetchBookableCountries not implemented')
        },
        fetchHierarchy: () => {
          throw new Error('fetchHierarchy not implemented')
        },
      },
    },
  )
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export type AppMenuLocationsMachine = Interpreter<
AppMenuLocationsMachineContext,
any,
Event
>

function getCountryPath(fullPath: string) {
  return `${fullPath.split('/')[0]}/`
}

function mapCountriesToNodes(countries: Hierarchy[]): LocationNode[] {
  return countries.map(({ name, path, type }) => ({
    children: [],
    label: name,
    parentPath: '/',
    path,
    type,
  }))
}

function generateNodeHierarchy({
  node,
  path,
}: {
  node: FullHierarchy
  path: string
}) {
  const locationNodes: LocationNode[] = []
  for (const subNode of node.children || []) {
    const newNode: LocationNode = {
      ...subNode,
      label: subNode.name,
      children: [],
      parentPath: node.path || '/',
    }
    if (path.startsWith(subNode.path)) {
      newNode.children = generateNodeHierarchy({ node: subNode, path })
    }
    locationNodes.push(newNode)
  }
  return locationNodes
}
