
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'

@Component
export default class SearchSummaryBarDetailsCalendar extends Vue {
  @Prop()
  dateRange: { arrive: string; depart: string }

  get isLeftMonth(): boolean {
    const date = new Intl.DateTimeFormat(this.$i18n.locale, {
      month: 'short',
      day: 'numeric',
    }).format(new Date(this.dateRange.arrive))
    return date.match(/^\d/) ? false : true
  }
}
