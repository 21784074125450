
import { findLocale } from '../../../../apps/i18n/utils'
import { LocaleObject } from 'vue-i18n'
import { Component, Vue, Prop } from '~/utility/pu-class-decorator'
import { reportError } from '~/utility/reportError'

@Component
export default class AppMenuInternationalization extends Vue {
  @Prop()
    totalCounts: number

  get showLanguageOptions() {
    if (this.totalCounts === 0) return false
    else return true
  }

  get currentLocale(): LocaleObject {
    const foundLocale = findLocale(this.$i18n.locale, this.$i18n.locales)
    if (!foundLocale) {
      reportError(
        new Error('Coult not find current locale. Using default one'),
        undefined,
        undefined,
        {
          name: 'AppMenuInternationalization',
          context: 'currentLocale',
        },
      )
      return this.$i18n.locales[0]
    }
    return foundLocale
  }
  get localeName(): string {
    return this.currentLocale.name
  }

  closeLanguageSwitch() {
    this.closeSwitch('language-menu')
  }

  closeCurrencySwitch() {
    this.closeSwitch('currency-menu')
  }

  closeSwitch(className: string) {
    // tslint:disable-next-line semicolon no-extra-semicolon
    (
      document.querySelector(`.${className} .ui-collapse-header`) as HTMLElement
    ).click()
    this.$emit('select')
  }
}
