import { render, staticRenderFns } from "./AppMenuLocationsTree.vue?vue&type=template&id=1941993e&scoped=true"
import script from "./AppMenuLocationsTree.vue?vue&type=script&lang=ts"
export * from "./AppMenuLocationsTree.vue?vue&type=script&lang=ts"
import style0 from "./AppMenuLocationsTree.vue?vue&type=style&index=0&id=1941993e&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1941993e",
  null
  
)

export default component.exports

/* nuxt-component-imports */
installComponents(component, {AppMenuLocationNode: require('/app/components/layouts/components/app-menu/locations/AppMenuLocationNode.vue').default,BaseTree: require('/app/components/data/BaseTree.vue').default})
