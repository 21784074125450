
import { GLAMPING_CATEGORIES } from '~/utility/glamping'
import { Component, Vue } from '~/utility/pu-class-decorator'
import { CampsiteCategory } from '~/utility/types/CampsiteCategory'
import { getCampsiteCategoriesPresenter } from '~~/apps/categories/presenter-json/CampsiteCategoriesUpdaterJson'

@Component
export default class AppMenuCategories extends Vue {
  get categoriesList(): CampsiteCategory[] {
    const categoriesPresenter = getCampsiteCategoriesPresenter(
      this.$route.params.lang || 'en-gb',
    )
    return categoriesPresenter.list.filter(
      (category) => category.id.toString() != '13',
    )
  }

  get glampingCategories() {
    return this.categoriesList
      .filter(({ id }) => GLAMPING_CATEGORIES.includes(id))
      .map((category) => ({ ...category, isPositive: true }))
  }
}
