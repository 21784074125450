import { ErrorPlatformEvent, interpret } from 'xstate'
import { rootContainer } from '~/container'
import { getLangFallbackUrl } from '~/lang/utils/langfetch'
import { fetchWithCache } from '~/utility/fetchWithCache'
import sentryCapture from '~/utility/sentryCapture'
import { getHierarchyRepository } from '../../../../../apps/hierarchies/inversify.config'
import {
  AppMenuLocationsMachineContext,
  returnAppMenuLocationsMachine,
} from './machine'

export function appMenuLocationsMachineFactory(
  ctx: AppMenuLocationsMachineContext,
) {
  const machine = returnAppMenuLocationsMachine()
  return interpret(
    machine.withContext(ctx).withConfig({
      actions: {
        logError: (_, _event: any) => {
          const event: ErrorPlatformEvent = _event
          event.data.name = `appMenuLocationsMachine ${event.type} error`
          sentryCapture(event.data, rootContainer)
        },
      },
      services: {
        fetchBookableCountries: async () =>
          await getHierarchyRepository().getCountries(ctx.langCode),
        fetchHierarchy: async ({ currentCountryPath }) => {
          const url = getLangFallbackUrl(
            ctx.langCode,
            `/_/hierarchy/full-country-az-menu/?path=${currentCountryPath}`,
          )
          const cacheKey = `AppMenuLocationsMachine-fetchHierarchy-${
            (currentCountryPath || '').split('/', 1)[0]
          }-${ctx.langCode}`
          return await fetchWithCache({ url, cacheKey })
        },
      },
    }),
  )
}
