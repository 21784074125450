
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { LocationNode } from './types'

const CITY_TYPE = 3

@Component
export default class AppMenuLocationsTree extends Vue {
  @Prop()
    currentPath: string

  @Prop()
    locations: LocationNode[]

  selectLocation(node: LocationNode) {
    if (node.type !== CITY_TYPE) {
      this.$emit('locationChanged', this.getSelectedLocationPath(node))
    }
  }

  private getSelectedLocationPath(node: LocationNode) {
    return node.path !== '/' &&
      node.type !== CITY_TYPE &&
      node.children.length > 0
      ? node.parentPath
      : node.path
  }
}
