
import { Component, Prop, Vue } from '~/utility/pu-class-decorator'
import { LocationNode } from './types'

const CITY_TYPE = 3

@Component
export default class AppMenuLocationNode extends Vue {
  @Prop()
    currentPath: string

  @Prop()
    node: LocationNode

  get modifyFilters() {
    const baseFilters = { categoryIds: undefined, facets: undefined }
    return this.node.path === ''
      ? { ...baseFilters, hierarchyPath: undefined }
      : baseFilters
  }

  isNodeActive(path: string) {
    return path === this.currentPath && path !== '/'
  }

  isNodeHighlighted(node: LocationNode) {
    return (
      this.isCity(node) ||
      node.children.length > 0 ||
      (node.path === this.currentPath && node.path !== '/')
    )
  }

  isNodeCollapsable(node: LocationNode) {
    return node.path !== '' && !this.isCity(node) && node.children.length > 0
  }

  isNodeExpandable(node: LocationNode) {
    return node.path !== '' && !this.isCity(node) && node.children.length === 0
  }

  isGoToVisible(node: LocationNode) {
    return node.path === '/' || this.isCity(node)
  }

  private isCity(node: LocationNode) {
    return node.type === CITY_TYPE
  }
}
